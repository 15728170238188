import { defineStore } from 'pinia'

export const userMenuStore = defineStore('usermenu', {
	state: () => ({
		options: {
			refresh: true,
			refreshtop: true,
			selectedmenu: null
		}
	}),
	actions: {
	}
	// getters
	// actions
})
