import { defineStore } from 'pinia'

export const userLangStore = defineStore('lang', {
	state: () => ({
		options: {
			refreshlang: false,
			temp: {  }
		}
	}),
	actions: {

	}
	// getters
	// actions
})