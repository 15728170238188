import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { createHead } from '@unhead/vue'
import { createI18n } from 'vue-i18n'
import el from '@/locales/el.json'
import en from '@/locales/en.json'

import '@/assets/style.scss';
import '@/assets/grid.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import "bootstrap"
import "bootstrap-icons/font/bootstrap-icons.css"

const head = createHead()

const i18n = createI18n({
	locale: "en",
	fallbackLocale: "en",
	messages: { el, en },
	legacy: false
})

axios.interceptors.response.use((response) => {
	if (response.headers.jwttoken && response.headers.jwttokenren == 1) {
		localStorage.setItem('sendjwt', '1');
		/* DEBUG */
		console.log("response.headers", response.headers);
		console.log("response.headers.jwttoken", response.headers.jwttoken);
		localStorage.setItem("token", response.headers.jwttoken);
	}
	return response
}, (error) => {
	if (error.response.status == 302) {
		document.location = error.response.data.Location;
		return Promise.resolve(true);
	}
	return Promise.reject(error)
})

axios.interceptors.request.use((request) => {
	if (localStorage.getItem('sendjwt') == '1') {
		request.headers['donejwt'] = 1;
		localStorage.setItem('sendjwt', '0');
	}
	return request;
}, (error) => {
	return Promise.reject(error)
})

createApp(App) /*.use(createBootstrap())*/ .use(router).use(store).use(head).use(i18n).mount('#app')