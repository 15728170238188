<template>

	<nav class="navbar navbar-expand-lg navbar-light justify-content-start w-100" :class="{ 'main-nav' : main.isavailable }">
		<div class="container-fluid">
			<!-- <a class="navbar-brand" href="/" style="width:80px;"><img src="@/assets/img/techtitanslogo.jpeg" class="img-fluid"></a> -->
			<a class="navbar-brand" href="/" style="width:200px;"><img src="@/assets/img/t-tech-green.png" class="img-fluid"></a> <div class="position-absolute p-1" style="width:34px;top:10px;left:200px;"><img src="@/assets/beta.svg" class="img-fluid"></div>
			<button class="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse" id="navbarSupportedContent">
				<ul :class="[ main.isavailable ? 'ms-auto' : 'me-auto' ]" class="navbar-nav mb-2 mb-lg-0 text-start">
					<li class="nav-item" :class="{ 'text-white fw-semibold' : main.isavailable }">
						<router-link to="/" aria-current="page" class="nav-link active">Home</router-link>
					</li>
					<li class="nav-item dropdown" :class="{ 'text-white fw-semibold' : main.isavailable }">
						<a class="nav-link dropdown-toggle" href="#" id="navbarDropdownLearning" role="button" data-bs-toggle="dropdown" aria-expanded="false">
							About
						</a>
						<ul class="dropdown-menu shadow-sm" aria-labelledby="navbarDropdown">
							<li class="d-none d-lg-block">
								<TopLabel style="height: 110px" :showico="true">
									About
									<template v-slot:sublabel>TechTitans Info</template>
								</TopLabel>
							</li>
							<li>
								<router-link to="/t/about" aria-current="page" class="dropdown-item">About Us</router-link>
							</li>
							<li>
								<router-link to="/t/contact" aria-current="page" class="dropdown-item">Contact</router-link>
							</li>
							<li>
								<hr class="dropdown-divider">
							</li>
							<li>
								<router-link to="/members/subscriptions" aria-current="page" class="dropdown-item">Subscriptions</router-link>
							</li>
							
							<!-- <li>
								<router-link to="/c/community" aria-current="page" class="dropdown-item">Community</router-link>
							</li>
							<li>
								<router-link to="/c/affiliate" aria-current="page" class="dropdown-item">Affiliate Programs</router-link>
							</li-->
						</ul>
					</li>
					<li class="nav-item dropdown" :class="{ 'text-white fw-semibold' : main.isavailable }">
						<a class="nav-link dropdown-toggle" href="#" id="navbarDropdownLearning" role="button" data-bs-toggle="dropdown" aria-expanded="false">
							Learning
						</a>
						<ul class="dropdown-menu shadow-sm" aria-labelledby="navbarDropdown">
							<li class="d-none d-lg-block">
								<TopLabel style="height: 110px" :showico="true">
									Learning
									<template v-slot:sublabel>Lessons for each Section</template>
								</TopLabel>
							</li>
							<li>
								<router-link to="/s/gamedesign" aria-current="page" class="dropdown-item"><i class="bi bi-dpad"></i> Game Design</router-link>
							</li>
							<li>
								<router-link to="/s/animation" aria-current="page" class="dropdown-item"><i class="bi bi-box"></i> Animation</router-link>
							</li>
							<li>
								<router-link to="/s/sounddesign" aria-current="page" class="dropdown-item"><i class="bi bi-soundwave"></i> Sound Design</router-link>
							</li>
							<li>
								<router-link to="/s/webdevelopment" aria-current="page" class="dropdown-item"><i class="bi bi-cpu"></i> Web Development</router-link>
							</li>
							<li>
								<router-link to="/s/graphics" aria-current="page" class="dropdown-item"><i class="bi bi-palette"></i> Graphic/UI/UX Design</router-link>
							</li>
							<li>
								<router-link to="/s/marketing" aria-current="page" class="dropdown-item"><i class="bi bi-bar-chart"></i> Digital Marketing</router-link>
							</li>
							<li>
								<router-link to="/s/digitalart" aria-current="page" class="dropdown-item"><i class="bi bi-box2-heart"></i> Digital Art</router-link>
							</li>
							<!--li>
								<hr class="dropdown-divider">
							</li>
							<li>
								<a class="dropdown-item" href="#">Contests / Competitions</a>
							</li-->
						</ul>
					</li>
					<!--li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" href="#" id="navbarDropdownExplore" role="button" data-bs-toggle="dropdown" aria-expanded="false">
							Portfolios
						</a>
						<ul class="dropdown-menu shadow-sm" aria-labelledby="navbarDropdown">
							<li class="d-none d-lg-block">
								<TopLabel style="height: 110px" :showico="true">
									Portfolios
									<template v-slot:sublabel>Veniam dolore sed sint sed duis nostrud</template>
								</TopLabel>
							</li>
							<li>
								<router-link to="/pr/art" aria-current="page" class="dropdown-item">Digital Art</router-link>
							</li>
							<li>
								<router-link to="/pr/music" aria-current="page" class="dropdown-item">Music</router-link>
							</li>
							<li>
								<router-link to="/pr/code" aria-current="page" class="dropdown-item">Code Depository</router-link>
							</li>
						</ul>
					</li-->
					<!--li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" href="#" id="navbarDropdownExplore" role="button" data-bs-toggle="dropdown" aria-expanded="false">
							Marketplace
						</a>
						<ul class="dropdown-menu shadow-sm" aria-labelledby="navbarDropdown">
							<li class="d-none d-lg-block">
								<TopLabel style="height: 110px" :showico="true">
									Marketplace
									<template v-slot:sublabel>Veniam dolore sed sint sed duis nostrud</template>
								</TopLabel>
							</li>
							<li>
								<router-link to="/m/games" aria-current="page" class="dropdown-item">Game Art &amp; Design</router-link>
							</li>
							<li>
								<router-link to="/m/music" aria-current="page" class="dropdown-item">Music</router-link>
							</li>
							<li>
								<router-link to="/m/websites" aria-current="page" class="dropdown-item">Websites</router-link>
							</li>
							<li>
								<router-link to="/m/designs" aria-current="page" class="dropdown-item">UI/UX Designs</router-link>
							</li>
							<li>
								<router-link to="/m/order" aria-current="page" class="dropdown-item">Order / Place a bid</router-link>
							</li>
						</ul>
					</li-->
					<!--li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" href="#" id="navbarDropdownExplore" role="button" data-bs-toggle="dropdown" aria-expanded="false">
							Careers
						</a>
						<ul class="dropdown-menu shadow-sm" aria-labelledby="navbarDropdown">
							<li class="d-none d-lg-block">
								<TopLabel style="height: 110px" :showico="true">
									Careers
									<template v-slot:sublabel>Veniam dolore sed sint sed duis nostrud</template>
								</TopLabel>
							</li>
							<li>
								<router-link to="/j/findjob" aria-current="page" class="dropdown-item">Find a Job</router-link>
							</li>
							<li>
								<router-link to="/j/postjob" aria-current="page" class="dropdown-item">Post a Job</router-link>
							</li>
							<li>
								<router-link to="/j/expert" aria-current="page" class="dropdown-item">Find an Expert</router-link>
							</li>
							<li>
								<router-link to="/j/companies" aria-current="page" class="dropdown-item">Find a Company</router-link>
							</li>
						</ul>
					</li-->
					<!-- <li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" href="#" id="navbarDropdownExplore" role="button" data-bs-toggle="dropdown" aria-expanded="false">
							Blog
						</a>
						<ul class="dropdown-menu shadow-sm" aria-labelledby="navbarDropdown">
							<li class="d-none d-lg-block">
								<TopLabel style="height: 110px" :showico="true">
									Blog
									<template v-slot:sublabel>Veniam dolore sed sint sed duis nostrud</template>
								</TopLabel>
							</li>
							<li>
								<router-link to="/c/community" aria-current="page" class="dropdown-item">Community</router-link>
							</li>
							<li>
								<router-link to="/c/affiliate" aria-current="page" class="dropdown-item">Affiliate Programs</router-link>
							</li>
						</ul>
					</li> -->
					<!--li class="nav-item">
						<router-link to="/p/teamup" aria-current="page" class="nav-link active">Team Up</router-link>
					</li-->
					
					<span v-if="loggedin" class="d-lg-none">
							<li class="nav-item dropdown">
								<a class="nav-link dropdown-toggle" href="#" id="navbarDropdownLearning" role="button" data-bs-toggle="dropdown" aria-expanded="false">
									My Account
								</a>
								<ul @click="umenu.refresh=!umenu.refresh" class="dropdown-menu shadow-sm" aria-labelledby="navbarDropdown">
									<li>
										<router-link to="/u/my/gamedashboard" aria-current="page" class="dropdown-item">Game Dashboard</router-link>
									</li>
									<li>
										<router-link to="/u/my/account" aria-current="page" class="dropdown-item">My Account</router-link>
									</li>
									<li>
										<hr class="dropdown-divider">
									</li>
									<li>
										<a role='button' @click="logOut" class="dropdown-item">Sign Out</a>
									</li>
								</ul>
							</li>
					</span>
					<span v-if="!loggedin" class="mb-2 me-4" style="margin-left: -4px;"><a href="/login"><button type="button" class="btn btn-sm btn-light d-lg-none text-nowrap">Sign In</button></a></span>
					<LanguageSelector class="me-4 d-lg-none" style="margin-left: 10px;"/>
				</ul>
			</div>
		</div>
	</nav>
</template>
<script lang="ts" setup>
import { onBeforeMount } from 'vue';
import axios from 'axios';
import TopLabel from "@/components/elements/TopLabel.vue";
import LanguageSelector from '@/components/elements/LanguageSelector.vue';
import { getCookie, generateRandomAlphaNum } from "@/common/common.js";
import { userMenuStore } from '@/store/userMenuStore';
import { userMainStore } from '@/store/userMainStore';

const main = userMainStore();
const umenu = userMenuStore();
let token = localStorage.getItem("token");

let loggedin = false;

onBeforeMount(
	async () => {
		if (getCookie("uid")) {
			loggedin = true;
		}
	});


const logOut = async () => {
	await axios
		.get('/api/v1/logout', {
			headers: { Authorization: `${token}` },
			withCredentials: true
		})
		.then(() => {
			localStorage.removeItem("token");
			document.location = "/login?" + generateRandomAlphaNum(5);
		});
}

</script>
<style scoped lang="scss">

#app nav>.nav-link {
	font-weight: 600;
	border-radius: 0;
	border-bottom: 1px solid transparent;
}

nav .dropdown-item {
	margin: 6px auto;
}

.navbar-brand:hover, .navbar-brand:focus {
    border-bottom: none;
}

#app nav a.router-link-active.router-link-exact-active.nav-link,
#app nav .nav-link.dropdown-toggle.show {
	border-radius: 0;
}

#app .nav-link.dropdown-toggle.show {
	xbackground-color: var(--tt-1);
}

#app .nav-link {
	border-bottom: 1px solid transparent;
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;
}

#app .nav-link:hover {
	border-bottom: 1px solid var(--tt-1) !important;
}

#app nav .dropdown-menu a:hover {
	border-bottom: none !important;
}

.dropdown-menu {
	--bs-dropdown-border-color: #f1f1f1;
	padding: 30px;
	min-width: 300px;
}

.nav-item.dropdown .dropdown-item {
	x-color: var(--tt-1);
}

.dropdown-item:hover,
.dropdown-item:focus {
	color: rgba(var(--tt-1-rgb), 1);
	background-color: transparent;
}

/*
nav .dropdown-menu a.router-link-exact-active:hover {
	background-color: #000;
}
*/

nav .dropdown-menu a.router-link-exact-active {
	color: var(--x-link-active-color);
	background-color: var(--tt-11);
	background-image: var(--gradient11) !important;
	border-radius: 5px;
}

</style>