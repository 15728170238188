<template>
	<div class="dropdown langdropdown">
		<button  :class="[ main.isavailable ? 'text-white homepage' : 'text-black' ]" class="lang-btn btn btn-sm btn-secondary dropdown-toggle text-uppercase" type="button" id="lang" data-bs-offset="0, 13" data-bs-toggle="dropdown" aria-expanded="false">
			<i class="bi bi-globe ms-lg-0 ms-md-0 ms-1"></i> {{ currentLanguage }}
		</button>
		<ul class="dropdown-menu shadow-sm" aria-labelledby="lang">
			<li><a class="dropdown-item" @click="setLang('en')" href="#">EN</a></li>
			<li><a class="dropdown-item" @click="setLang('el')" href="#">ΕΛ</a></li>
		</ul>
	</div>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { userLangStore } from '@/store/userLangStore';
import { userMainStore } from '@/store/userMainStore';

const main = userMainStore();
const langstore = userLangStore();

const t = useI18n();
t.locale.value = localStorage.getItem('locale') || 'en';
const currentLanguage = ref(t.locale.value);

const setLang = (lang) => {
	currentLanguage.value = lang;
	t.locale.value = lang;
	localStorage.setItem("locale", lang);
	langstore.refreslang = !langstore.refreslang;
}
</script>
<style scoped lang="scss">
.langdropdown {
	min-width: 64px;
}

.btn {
	border: none;
}

.btn.lang-btn {
	background-color: transparent!important;
}

.btn.lang-btn.mainpage:hover {
	background-color: transparent!important;
}

.btn.lang-btn:hover {
	background-color: #fff;
}
</style>