<template>
	<nav class="topnav">
		<!-- <router-link to="/" :class="{ 'router-link-active router-link-exact-active': $route.path == '/' || $route.path.startsWith('/d') }"><i class="bi bi-house"></i> Home</router-link> <span class="router-menu-split">|</span> -->
		<!--router-link to="/e/exploreworlds" class="d-none d-md-inline d-lg-inline"><i class="bi bi-binoculars"></i> Explore Worlds</router-link> <span class="router-menu-split">|</span-->
		<router-link to="/s/gamedesign" class="d-none d-md-inline d-lg-inline"><i class="bi bi-dpad"></i> Game Design &amp; Development</router-link> <span class="router-menu-split">|</span>
		<router-link to="/s/animation"><i class="bi bi-box"></i> Animation</router-link><span class="router-menu-split">|</span>
		<router-link to="/s/sounddesign"><i class="bi bi-soundwave"></i> Sound Design</router-link><span class="router-menu-split">|</span>
		<router-link to="/s/webdevelopment"><i class="bi bi-cpu"></i> Web Development</router-link><span class="router-menu-split">|</span>
		<router-link to="/s/graphics"><i class="bi bi-palette"></i> Graphic/UX/UI Design</router-link><span class="router-menu-split">|</span>
		<router-link to="/s/marketing"><i class="bi bi-bar-chart"></i> Digital Marketing</router-link><span class="router-menu-split">|</span>
		<router-link to="/s/digitalart"><i class="bi bi-box2-heart"></i> Digital Art</router-link><span class="router-menu-split">|</span>
		<hr class="topnavhr">
	</nav>
</template>
<style lang="scss">

.topnav hr {
	color: var(--tt-3);
	margin: 7px 0 0 0;
	opacity:.1;
}

nav {
	text-align: center;
	margin-bottom: 20px;
}

nav.topnav a.router-link-exact-active {
	color: #000;
	background-color: #fff;
	border-radius: 5px;
	border: 1px solid var(--tt-1);
}

nav a {
	padding: 10px;
	border-bottom: 1px solid transparent;
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;
}

nav a:hover {
	border-bottom: 1px solid var(--tt-1);
}
</style>