<template>
	<div class="d-flex align-items-left justify-content-center bg-white">
		<span id="ftxt" style="color:#888;letter-spacing:-0.8px;padding:0;margin:0;font-weight:300;font-size:12px;position:relative;top:0.5px;">TechTitans</span> <img src="@/assets/img/t-tech-green-flat.png" class="img-fluid me-2 ms-2" style="width:20px;position:relative;top:0px;"> <span id="ftxt" style="color:#888;letter-spacing:-0.8px;padding:0;margin:0;font-weight:300;font-size:12px;position:relative;top:0.5px;">&copy; {{ cy }}</span> <br>
		<span id="ftxt" style="color:#888;letter-spacing:-0.8px;padding:0;margin:0;font-weight:300;font-size:12px;position:relative;top:0.5px;">
			<router-link style="color:#aaa;" class="ms-4 me-4 pb-2" to="/agreements/termsofuse">Terms of Use</router-link>
			<router-link style="color:#aaa;" to="/agreements/privacypolicy">Privacy Policy</router-link>
		</span>
	</div>

	<div class="d-flex align-items-center justify-content-center bg-white pe-4">
		
	</div>
</template>
<script lang="ts" setup>
let cy = new Date().getFullYear();
defineProps({
	msg: String

})
</script>
<style scoped lang="scss">
</style>