import { defineStore } from 'pinia'

export const userMainStore = defineStore('main', {
	state: () => ({
		options: {
			isopen: false,
			isavailable: false
		}
	}),
	actions: {

	}
	// getters
	// actions
})