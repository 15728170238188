<template>
	<div class="d-lg-flex w-100">
		<div class="flex-none w-100">
			<div class="input-group input-group-sm input-group-inline rounded-pill">
				<span class="input-group-text rounded-start-pill"><i class="bi bi-search me-2 mb-2"></i> </span>
				<input type="search" @keyup="doKeySearch" @keyup.enter="doSearch" @search="doClear" v-model="search" class="form-control ps-0 rounded-end-pill" placeholder="Search" aria-label="Search">
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
import { onMounted, ref } from 'vue'

const search = ref(null);

const props = defineProps({
	value: String,
	disablekeyup: Boolean
})

const emit = defineEmits(['doSearch', 'clear']);

onMounted(
	async () => {
		if (props.value) {
			search.value = props.value;
		}
	});

const doKeySearch = () => {
	if (!props.disablekeyup) emit('doSearch', search.value);
}

const doSearch = () => {
	emit('doSearch', search.value);
}

const doClear = () => {
	if (!search.value.length) emit('clear');
}

</script>
<style scoped lang="scss">
.rounded-pill {
	border-radius: 50rem !important;
}

input.form-control {
	background: #fff;
	border-left: none;
}

.input-group {
	border-radius: var(--x-border-radius);
	box-shadow: 0 1px 2px rgba(50, 50, 71, .08);
	flex-wrap: wrap;
	align-items: stretch;
	display: flex;
	background: #fff;
}

.input-group-inline .input-group-text {
	background: #fff;
	padding: 6px;
}

.input-group-inline>:not(:last-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
	border-right: 0;
	padding-right: .25rem;
	margin-right: 0 !important;
}

.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.input-group-text {
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
</style>