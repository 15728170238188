import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

// const LS_ROUTE_KEY = "platform";
// const exclude = [];
// const excludeStarts = ["/u"];

const routes: Array < RouteRecordRaw > = [{
		path: '/',
		name: 'base',
		component: HomeView,
		children: [{
				name: 'home',
				path: '',
				components: {
					sidemain: () => import('../pages/Main.vue'),
				},
			},
			{
				name: 'aboutus',
				path: '/t/about',
				components: {
					sidemain: () => import('../pages/techtitans/AboutUs.vue'),
				},
			},
			{
				name: 'ourvalues',
				path: '/t/values',
				components: {
					sidemain: () => import('../pages/techtitans/Values.vue'),
				},
			},
			{
				name: 'contact',
				path: '/t/contact',
				components: {
					sidemain: () => import('../pages/techtitans/Contact.vue'),
				},
			},
			{
				name: 'gamedesign',
				path: '/s/gamedesign',
				components: {
					sidemain: () => import('../pages/ttsections/gamedesign/SectionGameDesign.vue'),
				},
			},
			// {
			// 	name: 'sectionvideo',
			// 	path: '/s/video/:video',
			// 	components: {
			// 		sectionmain: () => import('../components/ttsections/Video.vue'),
			// 	},
			// },
			{
				name: 'animation',
				path: '/s/animation',
				components: {
					sidemain: () => import('../pages/ttsections/animation/SectionAnimation.vue'),
				},
			},
			{
				name: 'sounddesign',
				path: '/s/sounddesign',
				components: {
					sidemain: () => import('../pages/ttsections/sounddesign/SectionSoundDesign.vue'),
				},
			},
			{
				name: 'webdevelopment',
				path: '/s/webdevelopment',
				components: {
					sidemain: () => import('../pages/ttsections/webdevelopment/SectionWebDevelopment.vue'),
				},
			},
			{
				name: 'graphics',
				path: '/s/graphics',
				components: {
					sidemain: () => import('../pages/ttsections/graphics/SectionGraphics.vue'),
				},
			},
			{
				name: 'marketing',
				path: '/s/marketing',
				components: {
					sidemain: () => import('../pages/ttsections/marketing/SectionDigitalMarketing.vue'),
				},
			},
			{
				name: 'sart',
				path: '/s/digitalart',
				components: {
					sidemain: () => import('../pages/ttsections/digitalart/SectionDigitalArt.vue'),
				},
			},
			{
				name: 'prart',
				path: '/pr/art',
				components: {
					sidemain: () => import('../pages/portfolios/Art.vue')
				}
			},
			{
				name: 'prmusic',
				path: '/pr/music',
				components: {
					sidemain: () => import('../pages/portfolios/Music.vue')
				}
			},
			{
				name: 'ccommunity',
				path: '/c/community',
				components: {
					sidemain: () => import('../pages/community/Community.vue')
				}
			},
			{
				name: 'caffiliate',
				path: '/c/affiliate',
				components: {
					sidemain: () => import('../pages/community/AffiliatePrograms.vue')
				}
			},
			{
				name: 'prcode',
				path: '/pr/code',
				components: {
					sidemain: () => import('../pages/portfolios/Code.vue')
				}
			},
			{
				name: 'pteamup',
				path: '/p/teamup',
				components: {
					sidemain: () => import('../pages/TeamUp.vue')
				}
			},
			{
				name: 'challenge',
				path: '/ch/challenge/:challenge',
				components: {
					sidemain: () => import('../pages/challenges/Challenge.vue')
				}
			},
			{
				name: 'job',
				path: '/j/job/:jon',
				components: {
					sidemain: () => import('../pages/jobs/Job.vue')
				}
			},
			// {
			// 	name: 'code',
			// 	path: '/p/code',
			// 	components: {
			// 		sidemain: () => import('../pages/Code.vue')
			// 	}
			// },
			// {
			// 	name: 'marketplace',
			// 	path: '/p/marketplace',
			// 	components: {
			// 		sidemain: () => import('../pages/Marketplace.vue')
			// 	}
			// },
			// {
			// 	name: 'jobs',
			// 	path: '/p/jobs',
			// 	components: {
			// 		sidemain: () => import('../pages/Jobs.vue')
			// 	}
			// },
			{
				name: 'exploreworlds',
				path: '/e/exploreworlds',
				components: {
					sidemain: () => import('../pages/ExploreWorlds.vue'),
				},
			},
			{
				name: 'mgames',
				path: '/m/games',
				components: {
					sidemain: () => import('../pages/marketplace/Games.vue'),
				},
			},
			{
				name: 'mmusic',
				path: '/m/music',
				components: {
					sidemain: () => import('../pages/marketplace/Music.vue'),
				},
			},
			{
				name: 'mwebsites',
				path: '/m/websites',
				components: {
					sidemain: () => import('../pages/marketplace/Websites.vue'),
				},
			},
			{
				name: 'mdesigns',
				path: '/m/designs',
				components: {
					sidemain: () => import('../pages/marketplace/Designs.vue'),
				},
			},
			{
				name: 'morder',
				path: '/m/order',
				components: {
					sidemain: () => import('../pages/marketplace/Order.vue'),
				},
			},
			{
				name: 'jfindjob',
				path: '/j/findjob',
				components: {
					sidemain: () => import('../pages/careers/FindJob.vue'),
				},
			},
			{
				name: 'jpostjob',
				path: '/j/postjob',
				components: {
					sidemain: () => import('../pages/careers/PostJob.vue'),
				},
			},
			{
				name: 'jexpert',
				path: '/j/expert',
				components: {
					sidemain: () => import('../pages/careers/Experts.vue'),
				},
			},
			{
				name: 'jcompanies',
				path: '/j/companies',
				components: {
					sidemain: () => import('../pages/careers/Companies.vue'),
				},
			},
			{
				name: 'search',
				path: '/d/search/:query',
				components: {
					sidemain: () => import('../pages/Search.vue'),
				},
			},
			{
				name: 'signup',
				path: '/members/signup',
				components: {
					sidemain: () => import('../pages/members/SignUp.vue'),
				},
			},
			{
				name: 'confirm',
				path: '/members/confirm/:key',
				components: {
					sidemain: () => import('../pages/members/Confirm.vue'),
				},
			},{
				name: 'sucesss',
				path: '/members/success',
				components: {
					sidemain: () => import('../pages/members/Success.vue'),
				},
			},
			// {
			// 	name: 'login',
			// 	path: '/login',
			// 	components: {
			// 		sidemain: () => import('../pages/login/Login.vue'),
			// 	},
			// },
			{
				name: 'subscriptions',
				path: '/members/subscriptions',
				components: {
					sidemain: () => import('../pages/members/Subscriptions.vue'),
				},
			},
			{
				name: 'subscribe',
				path: '/members/subscribe/:subscription',
				components: {
					sidemain: () => import('../pages/members/Subscribe.vue'),
				},
			},
			{
				name: 'memberscontact',
				path: '/members/contact/:subscription',
				components: {
					sidemain: () => import('../pages/members/Contact.vue'),
				},
			},
			{
				name: 'my',
				path: '/u/my/:page?',
				components: {
					sidemain: () => import('../pages/user/MyTab.vue'),
				},
			},
			{
				name: 'agreement',
				path: '/agreements/termsofuse',
				components: {
					sidemain: () => import('../pages/agreements/TermsOfUse.vue'),
				},
			},
			{
				name: 'privacypolicy',
				path: '/agreements/privacypolicy',
				components: {
					sidemain: () => import('../pages/agreements/PrivacyPolicy.vue'),
				},
			}
		]
	},
	// {
	// 	name: 'gamedesign',
	// 	path: '/s/gamedesign',
	// 		component: () => import('../components/ttsections/gamedesign/SectionGameDesign.vue'),

	// },
	// {
	// 	name: 'animation',
	// 	path: '/s/animation',
	// 		component: () => import('../components/ttsections/animation/SectionAnimation.vue'),

	// },
	// {
	// 	name: 'sounddesign',
	// 	path: '/s/sounddesign',
	// 	// components: {
	// 		component: () => import('../components/ttsections/sounddesign/SoundDesign.vue'),
	// 	// },
	// },
	{
		path: '/login',
		name: 'login',
		component: () => import('../pages/login/Login.vue')
	},
	// {
	// 	path: '/screen',
	// 	name: 'screen',
	// 	component: () => import('../views/ScreenView.vue')
	// },
	{
		path: '/admin',
		name: 'admin',
		component: () => import('../pages/admin/AdminMain.vue')
	},
	/* ToDo 
	{
		path: '/:catchAll(.*)*',
		name: "PageNotFound",
		component: PageNotFound,
	},
	*/
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

// router.beforeEach((to, from, next) => {

		// console.log("to.path", to.path);
		// if (!getCookie("uid")) {
		// 	if (exclude.includes(to.path)) {
		// 		document.location.href = "/login";
		// 		next();
		// 		return;
		// 	}
		// 	if (exclude.includes(to.path)) {
		// 		document.location.href = "/login";
		// 		next();
		// 		return;
		// 	}
		// }

	/* TO ALLOW LOGGEDIN USERS ONLY
	// if (!getCookie("uid")) {
	// 	document.location.href = "/login";
	// 	next();
	// 	return;
	// }
	*/
	// if (String(to.path) == "/" && (!String(from.path).startsWith("/d/") && String(from.path) != "/")) {
	// 	const lastRouteName = localStorage.getItem(LS_ROUTE_KEY) as string;
	// 	if (lastRouteName != "undefined" && lastRouteName != 'home2') {
	// 		next({ name: lastRouteName });
	// 		return;
	// 	}
	// } else {
	// 	if (from.name == 'home2' || String(from.path).startsWith("/d/")) localStorage.setItem(LS_ROUTE_KEY, from.name as string);
	// }

// 	next();
// });


export default router