<template>
	<div class="navtop" id="homepageheader" :class="[ main.isavailable? 'homepageheader' : 'nhpage' ]" >
		<div class="d-flex align-items-lg-center align-items-md-top " :class="{ 'maintop' : main.isavailable }" >
			<TopNavBar />
			<div v-if="filter.isavailable" class="ms-3 text-nowrap px-4 d-none d-lg-block">
				<button type="button" class="btn btn-light rounded-pill border px-5" @click="filter.isopen=!filter.isopen"><i class="bi bi-sliders"></i> Filter</button>
			</div>
			<div v-if="!main.isavailable" class="d-flex align-items-left justify-content-left col-2 col-md-2 mx-4 searchbox">
				<SearchBox @doSearch="doSearch" disablekeyup/>
			</div>
			<div class="navright d-flex ms-auto align-items-lg-center align-items-md-top mt-lg-0 mt-md-3">
				<!-- <ShoppingCart class="d-none d-md-block me-4"/> -->
				<TopNavBarUser v-if="loggedin" class="me-md-2" />
				<span v-if="!loggedin" class="ms-auto me-4"><a href="/login"><button type="button"  :class="{ 'bg-transparent border-0 text-white fw-semibold' : main.isavailable }" class="btn btn-sm btn-light d-none  d-lg-block text-nowrap">Sign In</button></a></span>
				<!-- <button v-if="loggedin" type="button" class="btn btn-sm btn-light d-none ms-auto d-lg-block me-4" @click="logOut">Sign Out</button> -->
				<LanguageSelector class="me-4 d-none d-lg-block" />
			</div>
		</div>
		<MainHeaderHero v-if="main.isavailable" />
	<!-- <TopAd v-if="$route.name == 'home'" style="height:200px;" class="d-none d-lg-block" />
	<TopBg v-if="$route.name == 'home'" style="height:100px;" class="d-none d-lg-block" /> -->
	<div class="mt-4" xv-else></div>
	<TopSectionNavBar v-if="!main.isavailable" class="d-none d-lg-block" />
	<MainDarkBarTop v-if="main.isavailable" class="topbbar" />
	</div>
	<router-view class="view sidemain overflow-x-hidden" name="sidemain"></router-view>
	<Footer v-if="!main.isavailable" />
	<MainFooter v-if="main.isavailable"/>
</template>
<script lang="ts" setup>
import { onBeforeMount } from 'vue';
// import axios from 'axios';
import Footer from '@/components/layout/Footer.vue';
import SearchBox from '@/components/elements/SearchBox.vue';
// import ShoppingCart from '@/components/elements/ShoppingCart.vue';
import LanguageSelector from '@/components/elements/LanguageSelector.vue';
import TopNavBar from '@/components/TopNavBar.vue';
import TopSectionNavBar from '@/components/TopSectionNavBar.vue';
import TopNavBarUser from '@/components/TopNavbarUser.vue';
import MainDarkBarTop from '@/components/mainpage/MainDarkBarTop.vue';
import MainHeaderHero from '@/components/mainpage/MainHeaderHero.vue';
import MainFooter from '@/components/mainpage/MainFooter.vue';
// import TopAd from '@/components/TopAd.vue';
// import TopBg from '@/components/TopBg.vue';
import { getCookie } from "@/common/common.js";
import { userMainStore } from '@/store/userMainStore';
import { userFilterStore } from '@/store/userFilterStore';

const main = userMainStore();
const filter = userFilterStore();

let loggedin = false;
// let token = localStorage.getItem("token");

defineProps({
	msg: String
})

onBeforeMount(
	async () => {
		if (getCookie("uid")) {
			loggedin = true;
		}
	});


const doSearch = (s) => {
	document.location = '/d/search/' + s;
}


</script>
<style scoped lang="scss">
.homepageheader {
	background: 
	linear-gradient(to bottom, rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.01)), 
	url('@/assets/img/homebg/init.png') no-repeat top center;
	background-size: cover;
	height: 100vh;
}

.nhpage {
	background: none!important;
}

.maintop {
	border-bottom: 1px solid #FFFFFE33;
}

.topbbar {
	position: absolute;
	top: calc(100vh - 66px);
}

.navtop {
	/*box-shadow: 0 0.055rem 0.10rem rgba(0, 0, 0, 0.075);*/
}

#app .btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--x-bs-btn-hover-bg);
    border-color: var(--x-bs-btn-hover-border-color);
}

@media  (max-width:  1450px) {
	.searchbox {
		display: none!important;
	}
}
</style>