<template>
	<div class="bg-black w-100 container-fluid">
		<div class="row col-12 ps-4 pe-4 mx-auto">
			<div class="d-flex">
				<div class="col-10">
					<div class="col-2 position-relative mb-4">
						<div class="my-4" style="width:150px;"><a class="navbar-brand" href="/"><img src="@/assets/img/t-tech-green.png" class="img-fluid"></a></div>
						<div class="text-white ftext">We inspire, empower, and innovate to shape the creators of tomorrow.</div>
						<div class="py-4"><svg width="205" height="24" viewBox="0 0 205 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g opacity="0.8">
									<path d="M12.0369 24C18.6847 24 24.0738 18.6274 24.0738 12C24.0738 5.37258 18.6847 0 12.0369 0C5.38911 0 0 5.37258 0 12C0 18.6274 5.38911 24 12.0369 24Z" fill="#F5F5F5" />
									<path d="M13.3704 19.6442V12.9569H15.608L16.0323 10.1923H13.3704V8.39456C13.3704 7.63921 13.7441 6.89897 14.9362 6.89897H16.1485V4.54228C16.1485 4.54228 15.0473 4.35596 14.0017 4.35596C11.8095 4.35596 10.375 5.68034 10.375 8.07732V10.1873H7.9353V12.9518H10.375V19.6392H13.3754L13.3704 19.6442Z" fill="#161616" />
									<path d="M48.1108 24C54.7586 24 60.1477 18.6274 60.1477 12C60.1477 5.37258 54.7586 0 48.1108 0C41.463 0 36.0739 5.37258 36.0739 12C36.0739 18.6274 41.463 24 48.1108 24Z" fill="#F5F5F5" />
									<path d="M49.1311 11.159L53.1215 6.53125H52.1769L48.7118 10.5497L45.9438 6.53125H42.7515L46.9389 12.6043L42.7515 17.4536H43.696L47.3581 13.2136L50.2827 17.4536H53.4751L49.1311 11.154V11.159ZM47.838 12.6597L47.4137 12.0554L44.0395 7.24128H45.4942L48.2168 11.1238L48.6411 11.7281L52.182 16.7788H50.7272L47.838 12.6597Z" fill="#161616" />
									<path d="M84.1846 24C90.8324 24 96.2215 18.6274 96.2215 12C96.2215 5.37258 90.8324 0 84.1846 0C77.5368 0 72.1476 5.37258 72.1476 12C72.1476 18.6274 77.5368 24 84.1846 24Z" fill="#F5F5F5" />
									<path d="M87.5033 7.98682C87.0992 7.98682 86.7709 8.31413 86.7709 8.71699C86.7709 9.11984 87.0992 9.44716 87.5033 9.44716C87.9074 9.44716 88.2357 9.11984 88.2357 8.71699C88.2357 8.31413 87.9074 7.98682 87.5033 7.98682Z" fill="#161616" />
									<path d="M84.2352 8.92334C82.533 8.92334 81.149 10.3031 81.149 12.0001C81.149 13.6972 82.533 15.0769 84.2352 15.0769C85.9375 15.0769 87.3215 13.6972 87.3215 12.0001C87.3215 10.3031 85.9375 8.92334 84.2352 8.92334ZM84.2352 13.9691C83.1442 13.9691 82.2602 13.0828 82.2602 12.0001C82.2602 10.9175 83.1492 10.0312 84.2352 10.0312C85.3212 10.0312 86.2103 10.9175 86.2103 12.0001C86.2103 13.0828 85.3212 13.9691 84.2352 13.9691Z" fill="#161616" />
									<path d="M86.6849 18.2443H81.6843C79.6082 18.2443 77.9211 16.5624 77.9211 14.4927V9.50743C77.9211 7.43777 79.6082 5.75586 81.6843 5.75586H86.6849C88.7609 5.75586 90.448 7.43777 90.448 9.50743V14.4927C90.448 16.5624 88.7609 18.2443 86.6849 18.2443ZM81.6843 6.92917C80.2598 6.92917 79.0981 8.08737 79.0981 9.50743V14.4927C79.0981 15.9128 80.2598 17.071 81.6843 17.071H86.6849C88.1093 17.071 89.2711 15.9128 89.2711 14.4927V9.50743C89.2711 8.08737 88.1093 6.92917 86.6849 6.92917H81.6843Z" fill="#161616" />
									<path d="M120.258 24C126.906 24 132.295 18.6274 132.295 12C132.295 5.37258 126.906 0 120.258 0C113.611 0 108.221 5.37258 108.221 12C108.221 18.6274 113.611 24 120.258 24Z" fill="#F5F5F5" />
									<path d="M125.456 10.7158C125.345 10.7259 125.234 10.7309 125.123 10.7309C123.905 10.7309 122.764 10.1166 122.097 9.09936V14.6487C122.097 16.9147 120.253 18.7527 117.98 18.7527C115.707 18.7527 113.864 16.9147 113.864 14.6487C113.864 12.3826 115.707 10.5446 117.98 10.5446C118.066 10.5446 118.152 10.5547 118.233 10.5597V12.579C118.147 12.5689 118.066 12.5538 117.98 12.5538C116.819 12.5538 115.879 13.4905 115.879 14.6487C115.879 15.8069 116.819 16.7435 117.98 16.7435C119.142 16.7435 120.163 15.832 120.163 14.6738L120.183 5.24707H122.122C122.304 6.97934 123.708 8.33394 125.451 8.46486V10.7108L125.456 10.7158Z" fill="#161616" />
									<path d="M156.295 24.0001C162.923 24.0001 168.295 18.644 168.295 12.0369C168.295 5.42983 162.923 0.0737305 156.295 0.0737305C149.668 0.0737305 144.295 5.42983 144.295 12.0369C144.295 18.644 149.668 24.0001 156.295 24.0001Z" fill="#F5F5F5" />
									<path d="M161.553 7.24269C160.556 6.78585 159.508 6.46456 158.426 6.27881C158.29 6.51978 158.133 6.84107 158.028 7.09711C156.88 6.92642 155.711 6.92642 154.563 7.09711C154.442 6.81597 154.301 6.54488 154.15 6.27881C153.068 6.45954 152.015 6.78585 151.023 7.24771C149.049 10.1795 148.515 13.0411 148.782 15.8624C149.94 16.7209 151.24 17.3735 152.619 17.7902C152.932 17.3685 153.204 16.9267 153.44 16.4598C152.992 16.2942 152.559 16.0883 152.146 15.8423C152.252 15.762 152.358 15.6817 152.458 15.5963C154.885 16.741 157.7 16.741 160.133 15.5963C160.233 15.6817 160.344 15.762 160.45 15.8423C160.037 16.0883 159.604 16.2942 159.156 16.4598C159.392 16.9217 159.669 17.3685 159.977 17.7852C161.356 17.3685 162.655 16.7159 163.814 15.8574C164.126 12.5892 163.275 9.7528 161.558 7.23767L161.553 7.24269ZM153.778 14.1304C153.027 14.1304 152.418 13.4477 152.418 12.6093C152.418 11.7709 153.017 11.0832 153.778 11.0832C154.538 11.0832 155.147 11.7659 155.142 12.6043C155.137 13.4427 154.543 14.1304 153.778 14.1304ZM158.813 14.1304C158.063 14.1304 157.454 13.4477 157.454 12.6093C157.454 11.7709 158.053 11.0832 158.813 11.0832C159.574 11.0832 160.183 11.7659 160.173 12.6043C160.163 13.4427 159.574 14.1304 158.813 14.1304Z" fill="#161616" />
									<path d="M192.295 24C198.923 24 204.295 18.6274 204.295 12C204.295 5.37258 198.923 0 192.295 0C185.668 0 180.295 5.37258 180.295 12C180.295 18.6274 185.668 24 192.295 24Z" fill="#F5F5F5" />
									<path d="M198.127 10.479C198.127 9.06399 196.984 7.9209 195.569 7.9209H189.027C187.612 7.9209 186.469 9.06399 186.469 10.479V13.5206C186.469 14.9356 187.612 16.0787 189.027 16.0787H195.569C196.984 16.0787 198.127 14.9356 198.127 13.5206V10.479ZM194.279 12.2264L191.349 13.6767C191.233 13.7371 190.845 13.6565 190.845 13.5256V10.5445C190.845 10.4136 191.238 10.3279 191.354 10.3934L194.164 11.9192C194.279 11.9847 194.405 12.1559 194.284 12.2214L194.279 12.2264Z" fill="#161616" />
								</g>
							</svg>
						</div>
					</div>
				</div>
				<div class="col-2 my-4">
					<div class="d-flex flex-column align-items-end">
						<router-link to="/" class="text-white">Home</router-link>
						<router-link to="/s/gamedesign" class="text-white">Learning</router-link>
						<router-link to="/members/subscriptions" class="text-white">Subscriptions</router-link>
						<router-link to="/t/about" class="text-white">About us</router-link>
						<router-link to="/t/contact" class="text-white">Contact</router-link>
					</div>
				</div>
			</div>
			<hr style="color:#f5f5f5" />
			<div class="d-flex">
				<div class="col-10">
					<div class="row w-100">
						<div class="py-4"><span class="text-white cprtext">Copyright &copy; {{ cy }} TechTitans All Rights Reserved</span> <br></div>
					</div>
				</div>
				<div class="col-2 my-4">
					<div class="d-flex justify-content-end">
						<router-link style="font-size:12px;" class="text-white me-4" to="/agreements/termsofuse">Terms of Use</router-link>
						<router-link style="font-size:12px;" class="text-white" to="/agreements/privacypolicy">Privacy Policy</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
let cy = new Date().getFullYear();
defineProps({
	msg: String

})
</script>
<style scoped lang="scss">
.ftext {
	font-size: 12px;
	font-weight: 400;
	line-height: 13.88px;
	text-align: left;
}

.cprtext {
	font-size: 12px;
	font-weight: 400;
	line-height: 14.06px;
	text-align: right;
}
</style>