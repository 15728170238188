<template>
	<div class="mainbar d-flex justify-content-center align-items-center  text-center w-100 text-white bg-black" style="background:var(--tt-1)">
		Take advantage of our exclusive discounted price during our development phase! Join now and embark on an exciting learning journey like never before.
	</div>
</template>
<script lang="ts" setup>
</script>
<style scoped lang="scss">
.mainbar {
	background: #161616;
	font-weight: 400;
	font-size: 16px;
	height: 66px;
}

@media (max-width: 768px) {
	.mainbar {
		font-size: 12px;
	}
}

</style>