<template>
	<nav class="navbar navbar-expand-lg navbar-light ms-auto">
		<div class="container-fluid">
			<div class="collapse navbar-collapse" id="navbarUser">
				<ul class="navbar-nav me-auto mb-2 mb-lg-0">
					<li class="nav-item dropdown" :class="{ 'text-white' : main.isavailable }">
						<a class="nav-link dropdown-toggle" href="#" id="navbarDropdownExplore" role="button" data-bs-toggle="dropdown" aria-expanded="false">
							<BAvatar v-if="!avatar" rounded="circle"><i class="bi bi-person-fill"></i><!--My Account --></BAvatar>
							<BAvatar v-if="avatar" rounded="circle" :src="avatar" />
						</a>
						<ul @click="umenu.refresh=!umenu.refresh" class="dropdown-menu shadow-sm dropdown-menu-end" aria-labelledby="navbarDropdown">
							<li>
								<TopLabel style="height: 110px" :showico="true">
									My Account
									<template v-slot:sublabel>Account Settings</template>
								</TopLabel>
							</li>
							<li>
								<router-link to="/u/my/gamedashboard" aria-current="page" class="dropdown-item">Game Dashboard</router-link>
							</li>
							<li>
								<router-link to="/u/my/account" aria-current="page" class="dropdown-item">My Account</router-link>
							</li>
							<!--li>
								<router-link to="/u/my/profile" aria-current="page" class="dropdown-item">Profile</router-link>
							</li-->
							<!--li>
								<router-link to="/u/my/portfolio" aria-current="page" class="dropdown-item">Portfolio</router-link>
							</li-->
							<!--li>
								<router-link to="/u/my/marketplace" aria-current="page" class="dropdown-item">My Marketplace</router-link>
							</li-->
							<!--li>
								<router-link to="/u/my/career" aria-current="page" class="dropdown-item">My Career</router-link>
							</li-->
							<!--li>
								<router-link to="/u/my/projects" aria-current="page" class="dropdown-item">My Projects</router-link>
							</li-->
							<!--li>
								<router-link to="/u/my/finances" aria-current="page" class="dropdown-item">Finances</router-link>
							</li-->
							<li>
								<hr class="dropdown-divider">
							</li>
							<li>
								<a role='button' @click="logOut" class="dropdown-item">Sign Out</a>
							</li>
						</ul>
					</li>
				</ul>
			</div>
		</div>
	</nav>
</template>
<script lang="ts" setup>
import { watch } from 'vue'
import axios from 'axios';
import { userMenuStore } from '@/store/userMenuStore';
import TopLabel from "@/components/elements/TopLabel.vue";
import { BAvatar } from 'bootstrap-vue-next';
import { generateRandomAlphaNum } from "@/common/common.js";
import { userMainStore } from '@/store/userMainStore';

const main = userMainStore();
// const emit = defineEmits(['refreshMenu']);
const umenu = userMenuStore();

let token = localStorage.getItem("token");
let avatar = localStorage.getItem("avatar");

// let username = getCookie("uname");
// console.log("username", username);

watch(() => umenu.refreshtop, () => {
	let slm = umenu.selectedmenu;
	let navbarUser = document.querySelectorAll('#navbarUser >ul  ul.dropdown-menu li > a.dropdown-item');
	navbarUser.forEach((item) => {
		item.classList.remove("router-link-active");
		item.classList.remove("router-link-exact-active");
		if (item.getAttribute('href')?.endsWith(slm)) {
			item.classList.add("router-link-active");
			item.classList.add("router-link-exact-active");
		}
	})
});

const logOut = async () => {
	await axios
		.get('/api/v1/logout', {
			headers: { Authorization: `${token}` },
			withCredentials: true
		})
		.then(() => {
			localStorage.removeItem("token");
			document.location = "/login?" + generateRandomAlphaNum(5);
		});
}


// function getCookie(id: unknown) {
// 	const value = document.cookie.match('(^|;)?' + id + '=([^;]*)(;|$)');
// 	// console.log("ocument.cookie", document.cookie);
// 	return value ? unescape(value[2]) : null;

// }
</script>
<style scoped lang="scss">
#app nav>.nav-link {
	font-weight: 600;
	border-radius: 0;
	border-bottom: 1px solid transparent;
}

nav .dropdown-item {
	margin: 6px auto;
}

#app nav a.router-link-active.router-link-exact-active.nav-link,
#app nav .nav-link.dropdown-toggle.show {
	border-radius: 0;
}

#app .nav-link.dropdown-toggle.show {
	xbackground-color: var(--tt-1);
}


#app .nav-link:hover {
	border-bottom: 1px solid var(--tt-1) !important;
}

#app nav .dropdown-menu a:hover {
	border-bottom: none !important;
}

.dropdown-menu {
	--bs-dropdown-border-color: #f1f1f1;
	padding: 30px;
	min-width: 300px;
}

.nav-item.dropdown .dropdown-item {
	x-color: var(--tt-1);
}

.dropdown-item:hover,
.dropdown-item:focus {
	color: rgba(var(--tt-1-rgb), 1);
	background-color: transparent;
}

/*
nav .dropdown-menu a.router-link-exact-active:hover {
	background-color: #000;
}
*/

nav .dropdown-menu a.router-link-exact-active {
	color: var(--x-link-active-color);
	background-color: var(--tt-11);
	background-image: var(--gradient11) !important;
	border-radius: 5px;
}

#app .nav-link {
	border-bottom: 1px solid transparent;
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;
}
</style>