'use strict';

import axios from 'axios';
import CryptoJS from 'crypto-js';
import { addDays, differenceInDays } from 'date-fns';
import config from '@/config/config.json';

export function getCookie(id) {
	const value = document.cookie.match('(^|;)?' + id + '=([^;]*)(;|$)');
	return value ? unescape(value[2]) : null;

}

function getDecryptedKey(data, hashkey) {
	var bytes = CryptoJS.AES.decrypt(data, hashkey);
	return bytes.toString(CryptoJS.enc.Utf8);
}

export function checkLoggedIn() {
	if (getCookie("uid")) { 
		document.location.href = '/';
		return false;
	}
}

export function generateRandomAlphaNum(len) {
	var rdmstring = "";
	for (; rdmstring.length < len; rdmstring += Math.random().toString(36).substr(2));
	return rdmstring.substr(0, len);
}

export function checkSubscription() {
	if (!getCookie("us")) return false;
	let dkey = JSON.parse(getDecryptedKey(getCookie("us"), config.clientkey));
	let activedate = dkey.sdactivedate;
	let activeperiod = dkey.stperiod;
	let activedateperiod = addDays(activedate, activeperiod);
	let dif = differenceInDays(activedateperiod, new Date());
	if (dif < 0) {
		return false;
	}
	return true;
}

export async function sendMail(options) {
	let csrftoken = await getCRF();
	let token = localStorage.getItem("token");
	return new Promise(async (resolve) => {
		await axios
			.post('/api/v1/sendmail', options, {
				headers: {
					Authorization: `${token}`,
					'X-CSRF-TOKEN': csrftoken
				},
				withCredentials: true,
				withXSRFToken: true
			})
			.then(response => {
				console.log("response", response);
				if (response.data.Error) {
					resolve(false);
					return;
				}
				resolve(true);

			}).catch(error => {
				console.log("error", error);
				resolve(false);
			})
	})
}


export function getCRF() {
	return new Promise(async (resolve) => {
		await axios
			.get('/api/v1/getcrf', {
				withCredentials: true
			})
			.then(response => {
				resolve(response.data.crf);
			});
	})
}

export function validateEmail(email) {
	return String(email).toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		) ? true: false ;
}